import { I18N } from "aurelia-i18n";
import { Utils } from "utils/helpers/utils";
import { Router } from "aurelia-router";
import { UserService } from "services/identity/user-service";
import { AppConfig } from "app-config";
import { IdentityService } from "services/identity/identity-service";
import { LogManager, autoinject } from "aurelia-framework";

export const log = LogManager.getLogger("app.user.admin");

@autoinject
export class Admin {
  constructor(
    private identityService: IdentityService,
    private userService: UserService,
    private appConfig: AppConfig,
    private router: Router,
    private i18n: I18N,
  ) {}

  activate(parameters: any) {
    if (this.appConfig.isLoggedIn) {
      this.router.navigateToRoute("home");
      return;
    }

    const email = decodeURIComponent(parameters.email);
    const token = decodeURIComponent(parameters.token);

    this.identityService
      .adminLogin(email, token)
      .then(data => {
        this.appConfig.setToken(data, true);
        this.userService.getSettings().then(result => this.appConfig.setSettings(result));

        this.router.navigateToRoute("home");
      })
      .catch(() => Utils.showErrorToast(log, this.i18n.tr("pages.user.login.errors.general")));
  }
}
