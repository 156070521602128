import { I18N } from "aurelia-i18n";
import { WarehouseService } from "services/client/warehouse-service";
import { IWarehouse } from "domain/Client/IWarehouse";
import { TransportService } from "services/client/transport-service";
import { AppConfig } from "app-config";
import { Utils } from "utils/helpers/utils";
import { TKEvent } from "utils/enums/TKEvent";
import { EventAggregator } from "aurelia-event-aggregator";
import { Router } from "aurelia-router";
import { LogManager, autoinject, bindable } from "aurelia-framework";
import { CartService } from "services/cart/cart-service";
import { ICart } from "domain/Cart/ICart";
import { PriceType } from "utils/enums/PriceType";
import { IProduct } from "domain/Product/IProduct";
import { CartProductService } from "services/cart/cart-product-service";
import { ICartProduct } from "domain/Cart/ICartProduct";

export const log = LogManager.getLogger("app.cart.index");

@autoinject
export class Index {
  allowedTransportOptions: Map<string, string>;
  warehouses: IWarehouse[];

  cart: ICart;
  error: string;

  @bindable comment = "";
  selectedTransportAddress = "";

  totalWithoutVat = 0;
  totalVat = 0;
  totalWithVat = 0;

  options = {
    showImages: true,
    showEan: true,
    showStock: true,
    showWarehouse: true,
    showReason: false,
    showDisplayPrice: true,
  };

  constructor(
    private cartProductService: CartProductService,
    private cartService: CartService,
    private transportService: TransportService,
    private warehouseService: WarehouseService,
    private router: Router,
    private eventAggregator: EventAggregator,
    private appConfig: AppConfig,
    private i18n: I18N,
  ) {
    this.eventAggregator.subscribe(TKEvent.updateCartTotal, () => {
      this.updateTotal();
    });
    this.eventAggregator.subscribe(TKEvent.cartProductsChanged, () => {
      this.updateTotal();
    });
    this.eventAggregator.subscribe(TKEvent.cartProductsWarehouseChanged, (product: IProduct) => {
      this.updateTotal();
    });
  }

  attached() {
    this.loadTransportsThenCart();
    this.loadWarehouses();
  }

  // #region OBSERVABLES, LISTENERS

  updateTotal() {
    if (this.cart == undefined || this.cart.products == undefined) return;

    this.totalWithoutVat = 0;

    this.cart.products.forEach(p => {
      const wholesale = p.selectedStock?.prices.find(price => price.type == PriceType.Wholesale);
      if (wholesale != null) this.totalWithoutVat += (wholesale.withoutVat ?? 0) * p.quantity;
    });
    this.totalWithoutVat = Utils.round(this.totalWithoutVat);

    this.totalVat = Utils.round(this.totalWithoutVat * AppConfig.vatRate);
    this.totalWithVat = this.totalWithoutVat + this.totalVat;
  }

  // #endregion

  // #region LOAD
  loadTransportsThenCart() {
    this.transportService
      .getAllowedTransportOptions()
      .then(result => {
        this.allowedTransportOptions = result;
        this.loadCart();
      })
      .catch(() =>
        Utils.showErrorToast(log, this.i18n.tr("components.transport-options.errors.load")),
      );
  }

  loadWarehouses() {
    this.warehouseService.getWarehouses().then(result => {
      this.warehouses = result;
    });
  }

  loadCart() {
    this.cartService
      .fetchCurrentFull()
      .then(result => {
        this.cart = result;

        for (const product of this.cart.products) {
          product.warehouseId = product.warehouseId ?? 0;
          product.transportId = product.transportId?.toString() ?? "0";

          if (product.warehouseId == 0 && !product.externalWarehouseId)
            product.warehouseId = this.appConfig.userSettings.warehouseId;
        }

        this.updateTotal();
      })
      .catch(error => (this.error = Utils.getErrorMessage(error, this.i18n)));
  }

  // #endregion

  // #region ACTIONS

  placeOrder() {
    let cancel = false;
    this.cart.products.forEach((product: ICartProduct) => {
      if (cancel) return;

      if (!product.transportId || product.transportId == "0") cancel = true;
    });

    if (cancel) {
      Utils.showErrorToast(log, this.i18n.tr("components.transport-options.errors.none-selected"));
      return;
    }

    // Add selected transport address to the end of the comment
    if (this.selectedTransportAddress) {
      if (this.comment) this.comment += "; ";
      this.comment +=
        this.i18n.tr("pages.cart.fields.transport-address") + " " + this.selectedTransportAddress;
    }

    const placeOrderButton = document.getElementById(`placeOrderButton`) as HTMLButtonElement;
    placeOrderButton.disabled = true;

    this.cartService
      .placeOrder(this.comment)
      .then(() => {
        this.eventAggregator.publish(TKEvent.cartProductsChanged);
        this.router.navigateToRoute("orderIndex");
      })
      .catch(() => Utils.showErrorToast(log, this.i18n.tr("pages.cart.errors.submit")))
      .finally(() => {
        placeOrderButton.disabled = false;
      });
  }

  clearCart() {
    if (!confirm(this.i18n.tr("pages.cart.messages.confirm-clear"))) return;

    this.cartService
      .clearCart()
      .then(() => {
        this.loadCart();
        this.eventAggregator.publish(TKEvent.cartProductsChanged);
      })
      .catch();
  }

  // #endregion
}
